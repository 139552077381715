<template>
  <div class="equipmentList">
    <van-nav-bar
        title="检测设备列表"
        left-text="返回"    
        left-arrow   
        @click-left="toHome"  
      />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell-group class="mt10" v-for="(item, idx) in equList" :key="idx">
        <van-cell>
          <template #title>            
            <van-row type="flex" justify="space-between">              
              <van-col span="12"><h4 class="custom-title">{{item.equipment_code}}</h4></van-col>              
              <van-col span="12"><h4 class="custom-title">{{item.equipment_name}}</h4></van-col>
            </van-row>
            <van-row type="flex" justify="space-between">
              <van-col span="12"><span class="custom-title">设备型号：{{item.equipment_model}}</span></van-col>
              <van-col span="12"><span class="custom-title">侨昌编号：{{item.qc_code}}</span></van-col>
            </van-row>
            <van-row type="flex" justify="space-between">
              <van-col span="24"><span class="custom-title">生产厂家：{{item.equipment_supplier}}</span></van-col>             
            </van-row>
            <van-row type="flex" justify="space-between" style="display: none;">
              <van-col span="24"><span class="custom-title">备注：{{item.remark}}</span></van-col>             
            </van-row>            
            <van-row type="flex" justify="end">             
              <van-button plain hairline round type="info" size="mini" @click="toEdit(item.id)">编 辑</van-button>
              <van-button plain hairline round type="danger" size="mini" v-track="{triggerType:'click', title:'删除设备',currentUrl: $route.path,behavior: item.equipment_name+'-'+ item.equipment_model, duration: new Date().getTime()}" @click='delBtn(item.id,item.equipment_name)'>删 除</van-button>
            </van-row>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>    
    <!-- 编辑设备 -->
    <van-popup v-model="equShow" class="elasticlayer tac" :closeable='true' position='bottom' round>
      <h3 class="tac">编辑设备</h3>
      <van-cell-group inset height='350px'>      
        <van-field v-model="equipmentCode" label="设备编号" input-align="right" placeholder="请输入设备编号" readonly />        
        <van-field v-model="equipmentName" required label="设备名称" input-align="right" placeholder="请输入设备名称"  />       
        <van-field v-model="equipmentModel" required label="设备型号" input-align="right" placeholder="请输入设备型号"  />        
        <van-field v-model="equipmentSupplier" required label="生产厂家" input-align="right" placeholder="请输入生产厂家"  />
        <van-field v-model="qcCode" label="侨昌编号" input-align="right" placeholder="请输入侨昌编号"  />
        <van-field v-model="hgValue" label="使用状态" input-align="right" placeholder="请输入使用状态"  @click="hgShow = true" />
        <van-field v-model="productDate" required label="入厂日期" input-align="right" placeholder="请输入入厂日期" @click="dateShow = true" />                        
        <van-field v-model="remark" label="备注" input-align="right" placeholder="请输入备注"  />
      </van-cell-group>
      <van-button class="pre-btn" round block size="small" type="primary" @click="preservEdit" v-track="{triggerType:'click', title:'编辑设备',currentUrl: $route.path,behavior: this._data, duration: new Date().getTime()}" style="position: absolute;bottom: 30px;">保 存</van-button>
    </van-popup>
    <!-- 新增设备 -->
    <van-popup v-model="addShow" v-if="addShow" class="elasticlayer tac" :closeable='true' position='bottom' round>
      <h3 class="tac">新增设备</h3>       
      <van-cell-group inset height='350px'>              
        <van-field v-model="equipmentName" required label="设备名称" input-align="right" placeholder="请输入设备名称"  />       
        <van-field v-model="equipmentModel" required label="设备型号" input-align="right" placeholder="请输入设备型号"  />        
        <van-field v-model="equipmentSupplier" required label="生产厂家" input-align="right" placeholder="请输入生产厂家"  />
        <van-field v-model="qcCode" label="侨昌编号" input-align="right" placeholder="请输入侨昌编号"  />
        <van-field v-model="hgValue" label="使用状态" input-align="right" placeholder="请输入使用状态"  @click="hgShow = true" /> 
        <van-field :value="date" required label="入厂日期" input-align="right" placeholder="请输入入厂日期"  @click="dateShow = true" />                     
        <van-field v-model="remark" input-align="right" label="备注" placeholder="请输入备注"  />
      </van-cell-group>
      <van-button class="pre-btn" round block size="small" type="primary" @click="preservAdd" v-track="{triggerType:'click', title:'新增设备',currentUrl: $route.path,behavior: this._data, duration: new Date().getTime()}" style="position: absolute;bottom: 30px;">保 存</van-button>
    </van-popup>    
    <div class="add-one" title="新增设备" @click="addOne">
      <van-icon name="plus" color="#fff" size="50" />
    </div> 
    <van-calendar  v-model="dateShow" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" />  
    <van-popup v-model="hgShow" round position="bottom">
        <van-picker
          show-toolbar
          title="设备状态"
          :columns="hgList"
          value-key="value"
          @cancel="hgShow = false"
          @confirm="hgConfirm"        
        />
      </van-popup>    
  </div>
</template>
<script>
import Vue from 'vue';
import { List, Cell, CellGroup, Tag, Col, Row, Button, Popup, Field, Toast, Dialog, Icon, Calendar, Form,Picker,NavBar } from 'vant';
import collectSticker$ from 'dingtalk-jsapi/api/biz/chat/collectSticker';
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Calendar);
Vue.use(Form);
Vue.use(Picker);
Vue.use(NavBar);
export default {
  name: 'EquipmentList',
  data() {    
    return {
      page: 1,
      offset: 10,
      count: 0,
      equList: [],
      loading: false,
      finished: false,
      equShow: false,
      addShow: false,
      hgShow: false,
      date: '',
      dateShow: false, //时间插件
      equId: '', //id
      equipmentCode: '', //设备编号
      equipmentName: '', //设备名称
      equipmentModel: '', //设备型号
      equipmentSupplier: '', //生产厂家
      qcCode: '', //侨昌编号
      productDate: '', //生产日期
      remark: '', //备注
      hgValue: '', 
      use_status: 0, //使用状态
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(),
      hgList: [{
        id: 1,
        value: '正常'
      },{
        id: 0,
        value: '停用'
      }],      
    };
  }, 
  created: function () {
    
  },
  methods: {
    onLoad () {
      var params = {
        page: this.page++,
        offset: this.offset,
        equipment_name: ''
      }
      this.$get('equipment/get-equipment-list', 'api', params).then(res => {
        var data = res.data.list    
        var length1 = res.data.list.length
        var length2 = res.data.count
        this.count = res.data.count
        this.loading = false;
        this.equList = this.equList.concat(data);//追加数据           
        if (length1 >= length2) {  //数据全部加载完成
          this.finished = true;         
        }
      }).catch(err => {
        console.log(err)
        this.finished = true;
      })      
    },
    formatDate (date) {     
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }, 
    // 时间
    onConfirm (value) {
      this.dateShow = false
      this.date = this.formatDate(value);
      this.productDate = this.formatDate(value);
    },
    // 编辑
    toEdit(id) {      
      var that = this           
      this.equShow = true 
      this.equId = id         
      that.$post('equipment/get-equipment-detail', 'api', { id: id}).then(res => {         
        var data = res.data           
        that.equipmentCode = data.equipment_code, 
        that.equipmentName = data.equipment_name, 
        that.equipmentModel = data.equipment_model, 
        that.equipmentSupplier = data.equipment_supplier,
        that.productDate = data.product_date,
        that.qcCode = data.qc_code 
        that.remark = data.remark 
        that.use_status = data.use_status
        if(data.use_status == 1) {
          that.hgValue = '正常'
        }else {
          that.hgValue = '停用'
        }
      }, err => {
        console.log(err)
        Toast.fail(err.message);
      })               
    },
    // 编辑保存
    preservEdit(id) {
      var that = this
      var params = {
        id: this.equId,
        equipment_name: this.equipmentName,
        equipment_model: this.equipmentModel,
        equipment_supplier: this.equipmentSupplier,
        product_date: this.productDate,
        remark: this.remark,
        equipment_config: "",
        cc_code: "",
        qc_code: this.qcCode,
        use_status: this.use_status
      }
      // console.log(params)
      this.equShow = false    
      that.$post('equipment/save-equipment', 'api', params).then(res => {         
        // Toast.success(res.message);   
        this.$notify({ type: 'success', message: res.message })      
        that.page = 1
        that.equList = []
        this.finished = false;
        this.loading = true 
        that.onLoad()
      }, err => {
        console.log(err)
        // Toast.fail(err.message);
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 选择设备使用状态
    hgConfirm(value) {
      this.use_status = value.id
      this.hgValue = value.value
      this.hgShow = false
    },
    // 删除
    delBtn(id,name) {
      var that = this    
      Dialog.confirm({
        title: name,
        message:'确定要删除此设备',        
      }).then(() => {
        that.$post('equipment/delete-equipment', 'api', { id: id}).then(res => {     
          // Toast.success(res.message);
          this.$notify({ type: 'success', message: res.message })
          that.page = 1
          that.equList = []
          this.finished = false;
          this.loading = true 
          that.onLoad()
        }, err => {
          console.log(err)
          // Toast.fail(err.message);
          this.$notify({ type: 'warning', message: err.message })
        })
      }).catch(() => {
        // on cancel
      });
    },
    // 新增设备
    addOne() {       
      this.addShow = true  
      this.dateShow = false
      this.equipmentCode = '',
      this.equipmentName ='', 
      this.equipmentModel = '',
      this.equipmentSupplier = '',
      this.date = ''
      this.productDate= '' 
      this.remark = ''
      this.qcCode = ''
      this.hgValue = ''
      this.use_status = 0 
    },
    // 新增保存
    preservAdd() {
      var that = this
      var params = {
        equipment_name: this.equipmentName,
        equipment_model: this.equipmentModel,
        equipment_supplier: this.equipmentSupplier,
        product_date: this.productDate,
        remark: this.remark,
        equipment_config: "",
        cc_code: "",
        qc_code: this.qcCode,
        use_status: this.use_status
      }
      // console.log(params)  
      that.$post('equipment/add-equipment', 'api', params).then(res => {         
        // Toast.success(res.message); 
        this.addShow = false    
        this.$notify({ type: 'success', message: res.message })        
        that.page = 1
        that.equList = []
        this.finished = false;
        this.loading = true 
        that.onLoad()
      }, err => {
        console.log(err)
        // Toast.fail(err.message);
        this.addShow = true
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 返回
    toHome() {
      this.$router.push({
        path: '/home'     
      })
    } 
  }
};
</script>
<style scoped>
 .status{
  position: absolute;
  top: 10px;
  left: 10px;
  width: 42px;
  height: 42px;
  z-index: 0;
  opacity: 40%;
  transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg);
 }
 .border-tb{
  border-top: dashed 1px #f5f5f5;
  border-bottom: dashed 1px #f5f5f5;
  padding: 8px 0;
  margin: 8px 0;
 }
 .elasticlayer {
   height: 68%;
 }
 .tac {
   text-align: center;
 }
 .pre-btn {
   margin-top: 50px;
 }
 .add-one {
   width: 50px;
   height: 50px;
   background: #1989fa;
   position: fixed;
   bottom: 100px;
   z-index: 999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
 }
</style>